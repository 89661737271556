// import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { HomeComponent } from "./home/home.component";
import { NewApplicationComponent } from "./new-application/new-application.component";
// import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { RenewalComponent } from "./renewal/renewal.component";
import { FeedbackComponent } from "./feedback/feedback.component";
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SrpApplicationComponent } from "./srp-application/srp-application.component";
import { ApplicationDetailsComponent } from "./application-details/application-details.component";
import { UserlistComponent } from "./userlist/userlist.component";
import { OffencelistComponent } from "./offencelist/offencelist.component";
import { ReportComponent } from "./report/report.component";
import { PaymentComponent } from "./payment/payment.component";
import { FormsModule } from "@angular/forms";
import { NewsComponent } from "./news/news.component";
import { EventsComponent } from "./events/events.component";
import { LogoutComponent } from "./logout/logout.component";
import { AddComponent } from "./news/add/add.component";
import { AddeventComponent } from "./events/addevent/addevent.component";
import { AdduserComponent } from "./adduser/adduser.component";
import { IncidenceComponent } from "./incidence/incidence.component";
import { ModalModule } from "ngb-modal";
import { OffencemasterComponent } from "./offencemaster/offencemaster.component";
import { AboutusmasterComponent } from "./aboutusmaster/aboutusmaster.component";
import { EnforcerComponent } from "./enforcer/enforcer.component";
import { OffencemanagementComponent } from "./enforcer/offencemanagement/offencemanagement.component";
import { OffencereportComponent } from "./enforcer/offencereport/offencereport.component";
import { PaymentreportComponent } from "./enforcer/paymentreport/paymentreport.component";
import { IncidencereportComponent } from "./enforcer/incidencereport/incidencereport.component";
import { ChangepasswordComponent } from "./changepassword/changepassword.component";
import { ForgotpasswordComponent } from "./forgotpassword/forgotpassword.component";
import { RbpComponent } from "./rbp/rbp.component";
//import { DataTablesModule } from 'angular-datatables';
import { NO_ERRORS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { OffencegalleryComponent } from './offencegallery/offencegallery.component';
import { IncidencegalleryComponent } from './incidencegallery/incidencegallery.component';
import { OffenceviewComponent } from './offenceview/offenceview.component';
import { OffencemastermappingComponent } from './offencemastermapping/offencemastermapping.component';
import { CodeofconductComponent } from './codeofconduct/codeofconduct.component';
import { ContactComponent } from './contact/contact.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    NavbarComponent,
    HomeComponent,
    NewApplicationComponent,
    RenewalComponent,
    FeedbackComponent,
    LoginComponent,
    DashboardComponent,
    SrpApplicationComponent,
    ApplicationDetailsComponent,
    UserlistComponent,
    OffencelistComponent,
    ReportComponent,
    PaymentComponent,
    NewsComponent,
    EventsComponent,
    LogoutComponent,
    AddComponent,
    AddeventComponent,
    AdduserComponent,
    IncidenceComponent,
    OffencemasterComponent,
    AboutusmasterComponent,
    EnforcerComponent,
    OffencemanagementComponent,
    OffencereportComponent,
    PaymentreportComponent,
    IncidencereportComponent,
    ChangepasswordComponent,
    ForgotpasswordComponent,
    RbpComponent,
    OffencegalleryComponent,
    IncidencegalleryComponent,
    OffenceviewComponent,
    OffencemastermappingComponent,
    CodeofconductComponent,
    ContactComponent,
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ModalModule,
    BrowserModule,
    // DataTablesModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [HttpClient],
  bootstrap: [AppComponent],
})
export class AppModule {}
