import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  base_path = "https://app.zerowastebhutan.gov.bt/api/";
  //base_path = "http://localhost:8080/";

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getUsers() {
    return this.http
      .get<any>(
        this.base_path + 'getAllUser', this.httpOptions);
               
  }

  deleteUsers(item){
    return this.http
      .post<any>(
        this.base_path + 'deleteUser/'+item, this.httpOptions);
  }

  resetPassword(item){
    return this.http
      .post<any>(
        this.base_path + 'resetPassword/'+item, this.httpOptions);
  }

  deactivateUser(item){
    return this.http
      .post<any>(
        this.base_path + 'deactivateUser/'+item, this.httpOptions);
  }

  activateUser(item){
    return this.http
      .post<any>(
        this.base_path + 'activateUser/'+item, this.httpOptions);
  }

  getAllOffenceMaster() {
    return this.http
      .get<any>(
        this.base_path + 'getAllOffenceMaster', this.httpOptions);
  }

  getAllOffenceMasterMapping() {
    return this.http
      .get<any>(
        this.base_path + 'getAllOffenceMasterMapping', this.httpOptions);
  }

  getAllDzongkhag() {
    return this.http
      .get<any>(
        this.base_path + 'getAllDzongkhags', this.httpOptions);
  }

  getReport(item, date){
    return this.http
      .post<any>(
        this.base_path + 'getReport/'+date, item, this.httpOptions);
  }

  getIncidenceReport(item){
    return this.http
      .post<any>(
        this.base_path + 'getIncidenceReport/'+item, this.httpOptions);
  }

  registerUser(item) {
    return this.http
      .post<any>(
        this.base_path + 'registerUser', item, this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }

  loginValidate(item) {
    return this.http
      .post<any>(
        this.base_path + 'login', item, this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }

  
  getRoleWithCID() {
    return this.http
      .get<any>(
        this.base_path + 'getRoleWithCid', this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }
  

  submitIncidence(item) {
    return this.http
      .post<any>(
        this.base_path + 'submitIncidence', item, this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }

  submitOffence(item) {
    return this.http
      .post<any>(
        this.base_path + 'submitOffence', item, this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }

  updateOffence(item) {
    return this.http
      .post<any>(
        this.base_path + 'updateOffence', item, this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }
  
  getAllOffences() {
    return this.http
      .get<any>(
        this.base_path + 'getAllOffences', this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }

  getAllOffenceMasterByDzongkhag(dzoid) {
    return this.http
      .get<any>(
        this.base_path + 'getAllOffenceMasterByDzongkhag/'+dzoid, this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }

  getOffenceByCid(cidNo) {
    return this.http
      .get<any>(
        this.base_path + 'getOffenceByCID/'+cidNo, this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }
  
  getOffenceByToken(appNo){
    return this.http
      .get<any>(
        this.base_path + 'getOffenceByToken/'+appNo, this.httpOptions);
  }

  getNameFromAPI(cidNo) {
    return this.http
      .get<any>(
        this.base_path + 'getcitizendetails/'+cidNo, this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }

  getVehicleDetails(vehicleNo){
    return this.http
      .get<any>(
        this.base_path + 'getvehicledetails/'+vehicleNo, this.httpOptions);
  }

  getLicenseDetails(licenseNo){
    return this.http
      .get<any>(
        this.base_path + 'getlicensedetails/'+licenseNo, this.httpOptions);
  }

  getPassportDetails(passportNo){
    return this.http
      .get<any>(
        this.base_path + 'getpassportdetails/'+passportNo, this.httpOptions);
  }

  approveUser(item){
    return this.http
      .post<any>(
        this.base_path + 'approveUser', item, this.httpOptions);
  }

  updateUser(item){
    return this.http
      .post<any>(
        this.base_path + 'updateUser', item, this.httpOptions);
  }

  rejectUser(item){
    return this.http
      .post<any>(
        this.base_path + 'rejectUser', item, this.httpOptions);
  }

  submitNews(item) {
    return this.http
      .post<any>(
        this.base_path + 'addNews', item, this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }
  
  submitOffenceMaster(item) {
    return this.http
      .post<any>(
        this.base_path + 'addOffenceMaster', item, this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }

  submitOffenceMapping(item) {
    return this.http
      .post<any>(
        this.base_path + 'addOffenceMapping', item, this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }

  updateOffenceMapping(item) {
    return this.http
      .post<any>(
        this.base_path + 'updateOffenceMapping', item, this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }

  deleteOffenceMapping(item) {
    return this.http
      .post<any>(
        this.base_path + 'deleteOffenceMapping', item, this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }

  deleteOffenceMaster(item) {
    return this.http
      .post<any>(
        this.base_path + 'deleteOffenceMaster', item, this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }

  deleteNews(item) {
    return this.http
      .post<any>(
        this.base_path + 'deleteNews/'+item, this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }

  // submitNews(item, fileToUpload: File) {
  //   const formData: FormData = new FormData();
  //   formData.append('File', fileToUpload,  fileToUpload.name);
  //   //formData.append("Title", item.title)
  //   debugger
  //   return this.http
  //     .post<any>(
  //       this.base_path + 'addNews', formData, this.httpOptions);
  //     // .pipe(retry(2), catchError(this.handleError));
  // }

  getAllNews() {
    return this.http
      .get<any>(
        this.base_path + 'getAllNews', this.httpOptions);
  }

  getNewsById(id) {
    return this.http
      .get<any>(
        this.base_path + 'getNewsById/'+id, this.httpOptions);
  }
  
  getAllEvents() {
    return this.http
      .get<any>(
        this.base_path + 'getAllEvents', this.httpOptions);
  }

  getIncidences(){
    return this.http
      .get<any>(
        this.base_path + 'getIncidences', this.httpOptions);
  }

  getAllAboutUsMaster() {
    return this.http
      .get<any>(
        this.base_path + 'getAllAboutUsMaster', this.httpOptions);
  }

  addAboutUsMaster(item) {
    return this.http
      .post<any>(
        this.base_path + 'submitAboutUsMaster', item,  this.httpOptions);
  }

  deleteAboutUsMaster(item) {
    return this.http
      .post<any>(
        this.base_path + 'deleteAboutUsMaster', item,  this.httpOptions);
  }

  deleteOffenceById(id) {
    return this.http.post<any>(`${this.base_path}deleteOffenceById/${id}`, {}, { responseType: 'text' as 'json' });
  }
  //   return this.http
  //     .post<any>(
  //      // this.base_path + 'deleteOffenceById', id,  this.httpOptions);
  //       this.base_path + 'deleteOffenceById/'+id, this.httpOptions);
  // }

  changePIN(cid, oldPin, newPin){
    return this.http
      .get<any>(
        this.base_path + 'changePIN/'+cid+'/'+oldPin+'/'+newPin, this.httpOptions);
  }

  getCodeofconduct() {
    return this.http
      .get<any>(
        this.base_path + 'getCodeOfConduct', this.httpOptions);
  }

  submitCodeofConduct(item) {
    return this.http
      .post<any>(
        this.base_path + 'submitCodeOfConduct', item, this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }

  getContact() {
    return this.http
      .get<any>(
        this.base_path + 'getContactMaster', this.httpOptions);
  }

  submitContact(item) {
    return this.http
      .post<any>(
        this.base_path + 'submitContactMaster', item, this.httpOptions);
      // .pipe(retry(2), catchError(this.handleError));
  }

}
