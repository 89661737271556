import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NewApplicationComponent } from './new-application/new-application.component';
import { RenewalComponent } from './renewal/renewal.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SrpApplicationComponent } from './srp-application/srp-application.component';
import { ApplicationDetailsComponent } from './application-details/application-details.component';
import { UserlistComponent } from './userlist/userlist.component';
import { OffencelistComponent } from './offencelist/offencelist.component';
import { ReportComponent } from './report/report.component';
import { PaymentComponent } from './payment/payment.component';
import { NewsComponent } from './news/news.component';
import { EventsComponent } from './events/events.component';
import { LogoutComponent } from './logout/logout.component';
import { AddComponent } from './news/add/add.component';
import { AddeventComponent } from './events/addevent/addevent.component';
import { AdduserComponent } from './adduser/adduser.component';
import { IncidenceComponent } from './incidence/incidence.component';
import { OffencemasterComponent } from './offencemaster/offencemaster.component';
import { AboutusmasterComponent } from './aboutusmaster/aboutusmaster.component';
import { EnforcerComponent } from './enforcer/enforcer.component';
import { OffencemanagementComponent } from './enforcer/offencemanagement/offencemanagement.component';
import { OffencereportComponent } from './enforcer/offencereport/offencereport.component';
import { PaymentreportComponent } from './enforcer/paymentreport/paymentreport.component';
import { IncidencereportComponent } from './enforcer/incidencereport/incidencereport.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { RbpComponent } from './rbp/rbp.component';
import { OffencegalleryComponent } from './offencegallery/offencegallery.component';
import { IncidencegalleryComponent } from './incidencegallery/incidencegallery.component';
import { OffenceviewComponent } from './offenceview/offenceview.component';
import { OffencemastermappingComponent } from './offencemastermapping/offencemastermapping.component';
import { CodeofconductComponent } from './codeofconduct/codeofconduct.component';
import { ContactComponent } from './contact/contact.component';


const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'login', component: LoginComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'userlist', component: UserlistComponent},
  {path: 'offencelist', component: OffencelistComponent},
  {path: 'report', component: ReportComponent},
  {path: 'payment', component: PaymentComponent},
  {path: 'news', component: NewsComponent},
  {path: 'news-add', component: AddComponent},
  {path: 'events', component: EventsComponent},
  {path: 'add-event', component: AddeventComponent},
  {path: 'logout', component: LogoutComponent},
  {path: 'adduser', component: AdduserComponent},
  {path: 'incidence', component: IncidenceComponent},
  {path: 'offencemaster', component: OffencemasterComponent},
  {path: 'aboutusmaster', component: AboutusmasterComponent},
  {path: 'enforcer', component: EnforcerComponent},
  {path: 'enfoffencemanagement', component: OffencemanagementComponent},
  {path: 'enfoffencereport', component: OffencereportComponent},
  {path: 'paymentreport', component: PaymentreportComponent},
  {path: 'incidencereport', component: IncidencereportComponent},
  {path: 'changepassword', component: ChangepasswordComponent},
  {path: 'forgotpassword', component: ForgotpasswordComponent},
  {path: 'rbp', component: RbpComponent},
  {path: 'offencegallery', component: OffencegalleryComponent},
  {path: 'incidencegallery', component: IncidencegalleryComponent},
  {path: 'offenceview', component: OffenceviewComponent},
  {path: 'offencedzongkhagmapping', component: OffencemastermappingComponent},
  {path: 'codeofconduct', component: CodeofconductComponent},
  {path: 'contact', component: ContactComponent},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
